import { ApiConfig } from '@chilipiper/api-client/src/http-client-base'
import AuthService from '@chilipiper/api-client/src/auth'
import { JwtService } from './types'
import { jwtStore } from './jwtStore'
import { sanitizeErrors } from './apiClientConfig'

const getAuthApi = (options?: ApiConfig) => {
  return new AuthService(options)
}

const accessToken = 'CP_GUEST_TOKEN'

const { setAccessToken, getAccessToken, removeAccessToken, hasAccessToken } = jwtStore(accessToken)

let deduplicatedJwtRequest: Promise<string> | null = null

const getJwtDeduplicated = async (): Promise<string> => {
  if (!deduplicatedJwtRequest) {
    deduplicatedJwtRequest = getJWT()
    try {
      return await deduplicatedJwtRequest
    } finally {
      deduplicatedJwtRequest = null
    }
  }
  return deduplicatedJwtRequest
}

const getJWT = async () => {
  const token = await getAuthApi({
    customFetch: async (input, init = {}) => {
      return sanitizeErrors(await fetch(input, init))
    },
  }).postAuthV1PublicGuestTokens()

  setAccessToken(token)
  return token
}

export const guestJwt = {
  refresh: getJwtDeduplicated,
  getToken: getAccessToken,
  setToken: setAccessToken,
  getSessionData: () => undefined,
  login: getJwtDeduplicated,
  isAuthenticated: hasAccessToken,
  logout: removeAccessToken,
} satisfies JwtService<string>

/* eslint-disable */
/* tslint:disable */
export * from './http-client-base'

import { ApiConfig, FullRequestParams, HttpClient as HttpClientBase, QueryParamsType } from './http-client-base'
import services from './services.json'

type JsonKey = keyof typeof services

const addPrefix = (serviceName: string, path: string, isLBRequest: boolean) => {
  const serviceData =
    services[serviceName as JsonKey] || services[`${serviceName}-service` as JsonKey]

  if (!serviceData) {
    return path
  }

  const parsedPath = isLBRequest
    ? `${serviceData.lbPath}${path}`
    : `${serviceData.lbPathRewrite}${path}`

  return parsedPath
}

export class HttpClient<SecurityDataType = unknown> extends HttpClientBase<SecurityDataType> {
  public baseUrl: string = typeof global !== 'undefined' ? global.location?.origin ?? '' : '' //window is not defined on SSR apps and global not defined on service workers
  private useLBPath = true
  public clientRequest: HttpClient<SecurityDataType>['requestWithServiceName']
  public clientGetPath: HttpClient<SecurityDataType>['pathWithServiceName']
  private clientBaseRequest: HttpClientBase<SecurityDataType>['request']

  constructor({
    useLBPath = true,
    ...apiConfig
  }: ApiConfig<SecurityDataType> & { useLBPath?: boolean } = {}) {
    super(apiConfig)
    if (apiConfig.baseUrl) {
      this.baseUrl = apiConfig.baseUrl
    }
    this.useLBPath = useLBPath
    this.clientRequest = this.requestWithServiceName
    this.clientGetPath = this.pathWithServiceName
    this.clientBaseRequest = this.request
  }

  public pathWithServiceName = ({
    path,
    serviceName,
  }: {
    path: string
    serviceName: string
  }): string => {
    const prefixedPath = addPrefix(serviceName, path, this.useLBPath)
    return prefixedPath
  }

  // We need to use service name in order to properly map the request path to
  // our loadbalancer path
  public requestWithServiceName = <T = any, E = any>({
    path,
    serviceName,
    ...params
  }: FullRequestParams & { serviceName: string }): Promise<T> => {
    return this.clientBaseRequest<T, E>({
      ...params,
      path: this.pathWithServiceName({ path, serviceName }),
    })
  }

  public encodeQueryParams(rawQuery?: QueryParamsType): string {
   return this.toQueryString(rawQuery)
  }
}

export enum Products {
  Chat = 'chat',
  Concierge = 'concierge',
  Distro = 'distro',
  Events = 'events',
  Meetings = 'meetings',
  MildMeetings = 'mildMeetings',
}

export enum ProductStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface License {
  active: number
  total: number
}

export type Licenses = {
  [key in Products]: License
}

export type SessionLicense = {
  [key in Products]: {
    status: ProductStatus
  }
}

export enum AccessHistoryActionType {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
  Requested = 'Requested',
}
export enum LicensedProduct {
  Chat = 'Chat',
  Concierge = 'Concierge',
  Distro = 'Distro',
  Events = 'Events',
  Meetings = 'Meetings',
  MildMeetings = 'MildMeetings',
}

export type LicenseAccess = {
  action: AccessHistoryActionType
  author?: {
    id: string
    name: string
  }
  createdAt: string
  createdBy: string
  product: LicensedProduct
}

export interface TrialRaw {
  expirationDate?: string
  id: LicensedProduct
}

import AuthService, { TokenForm } from '@chilipiper/api-client/src/auth'
import { ApiConfig } from '@chilipiper/api-client/src/http-client'
import jwtDecode from 'jwt-decode'
import { nanoid } from 'nanoid'
import { jwtStore } from './jwtStore'
import {
  BasicAuthLogin,
  isBasicAuthLogin,
  isBasicAuthRefresh,
  JwtLogin,
  JwtService,
  PatchedTokenResponse,
} from './types'
import { getAuthType, sanitizeErrors, setupAuthHeadersV2 } from './apiClientConfig'
import { jwtApiV1, logoutWithoutRedirect } from './jwtApiV1'

export interface JwtResponse {
  chilipiper: {
    cluster: string
    tenantId: string
  }
  cpTenantId: string
  cpUserIdIam: string
  cpUserIdLegacy: string
  exp: number
  iat: number
  sub: string
}

export interface SessionV2 {
  cluster: string
  tenantId: string
  userId: string
}

const accessToken = 'CP_AT_V2'

const getAuthApi = (options?: ApiConfig) => {
  return new AuthService(options)
}

const { setAccessToken, getAccessToken, removeAccessToken, hasAccessToken } = jwtStore(accessToken)

let deduplicatedJwtRequest: Promise<PatchedTokenResponse> | null = null

const getJwtDeduplicated = async (params?: JwtLogin) => {
  if (!deduplicatedJwtRequest) {
    deduplicatedJwtRequest = getJWT(params)
    try {
      return await deduplicatedJwtRequest
    } finally {
      deduplicatedJwtRequest = null
    }
  }
  return deduplicatedJwtRequest
}
const getJWT = async (params?: JwtLogin) => {
  try {
    const isBasicAuth = isBasicAuthRefresh(params)
    const apiOptions = !isBasicAuth && typeof params !== 'string' ? params : {}
    const response = (await getAuthApi({
      customFetch: async (input, init = {} as RequestInit) => {
        const authType = getAuthType(input)
        if (isBasicAuth && authType === 'auth') {
          init.headers = {
            ...init.headers,
            authorization: `Basic ${window.btoa(`${params.clientId}:${params.clientSecret}`)}`,
          }
        }
        await setupAuthHeadersV2(init, authType)
        return sanitizeErrors(await fetch(input, init))
      },
      ...apiOptions,
    }).postAuthV1Refresh(
      isBasicAuth && params?.['jwt-refresh']
        ? {
            'jwt-refresh': params['jwt-refresh'],
          }
        : undefined
    )) as unknown as PatchedTokenResponse
    setAccessToken(response['jwt-access'])
    return response
  } catch {
    const { accessToken } = await jwtApiV1.login()
    const { jwtAccess } = await getAuthApi({
      customFetch: async (input, init = {} as RequestInit) => {
        init.headers = {
          ...(init.headers as Record<string, string>),
          'x-request-id': nanoid(),
        }
        if (accessToken && getAuthType(input) === 'auth') {
          init.headers.authorization = `Bearer ${accessToken}`
        }
        return sanitizeErrors(await fetch(input, init))
      },
    }).postAuthV1ConvertTokenLegacyToFire()
    setAccessToken(jwtAccess)
    return { 'jwt-access': jwtAccess, type: 'RefreshTokenInCookie' } as const
  }
}

const getPostToken = async (params: BasicAuthLogin) => {
  const response = await getAuthApi({
    customFetch: async (input, init = {} as RequestInit) => {
      const authType = getAuthType(input)
      if (authType === 'auth') {
        init.headers = {
          ...(init.headers as Record<string, string>),
          authorization: `Basic ${window.btoa(`${params.clientId}:${params.clientSecret}`)}`,
        }
      }
      await setupAuthHeadersV2(init, authType)
      return sanitizeErrors(await fetch(input, init))
    },
  }).postAuthV1Token({
    authCode: params.authCode,
    tenantId: params.tenantId,
  } as unknown as TokenForm)

  return response as unknown as PatchedTokenResponse
}

export const logoutV2 = async () => {
  await getAuthApi()
    .putAuthV1Logout()
    .then(() => {
      removeAccessToken()
    })
}

export const jwtApiV2 = {
  refresh: getJwtDeduplicated,
  getToken: getAccessToken,
  setToken: setAccessToken,
  getSessionData: () => {
    const token = getAccessToken()
    if (!token) {
      return null
    }
    const data = jwtDecode<JwtResponse>(token)
    return {
      tenantId: data.cpTenantId,
      userId: data.cpUserIdLegacy,
      cluster: data.chilipiper.cluster,
    }
  },
  login: (params?: JwtLogin) =>
    isBasicAuthLogin(params) ? getPostToken(params) : getJwtDeduplicated(params),
  isAuthenticated: hasAccessToken,
  logout: async () => {
    await Promise.all([logoutV2(), logoutWithoutRedirect()])
  },
} satisfies JwtService<PatchedTokenResponse, SessionV2>

export const redirectToLogin = () => {
  const currentQueryParams = new URLSearchParams(window.location.search)

  const loginQueryParams = new URLSearchParams()
  loginQueryParams.append('target-app', trimSlash(window.location.pathname))

  const crmParam = currentQueryParams.get('crm')
  if (crmParam) {
    loginQueryParams.append('crm', crmParam)
  }

  const trialParam = currentQueryParams.get('product-trial')
  if (trialParam) {
    loginQueryParams.append('product-trial', trialParam)
  }

  const params = loginQueryParams.toString()
  const authPath = `/api/fire-auth/v1/login`

  window.location.href = `${window.location.origin}${authPath}?${params}`
}

export const redirectToOutlook = (clientId: string) => {
  const loginQueryParams = new URLSearchParams()
  const redirectUri = process.env.REACT_APP_OUTLOOK_REDIRECT_URI
  if (redirectUri) {
    loginQueryParams.append('redirect-uri', redirectUri)
  }
  loginQueryParams.append('code-exchange', clientId)

  const params = loginQueryParams.toString()
  const authPath = `/auth/v1/login`

  window.location.href = `${window.location.origin}${authPath}?${params}`
}

const trimSlash = (url: string) => url.replace(/^\/|\/$/g, '')

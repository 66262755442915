import { store } from '@chilipiper/utils'

export const jwtStore = (accessTokenKey: string) => {
  return {
    setAccessToken: (token: string) => {
      store.set(accessTokenKey, token)
    },
    getAccessToken: () => store.get(accessTokenKey) as string | null,
    hasAccessToken: () => store.has(accessTokenKey),
    removeAccessToken: () => {
      store.remove(accessTokenKey)
    },
  }
}

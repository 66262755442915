import { endpoint } from '@chilipiper/config'

export const api = (uri = '') => `${endpoint.api()}${uri}`
export const auth = (uri = '') => `${endpoint.auth()}${uri}`
export const reportingApi = (uri = '') => `${endpoint.reporting()}${uri}`
export const inboxApi = (uri = '') => `${endpoint.inbox()}${uri}`
export const edge = (uri = '') => `${endpoint.edge()}${uri}`
export const homebase = (uri = '') => `${window.location?.origin}/${uri}`
export const profiles = (uri = '') => `${endpoint.profiles()}${uri}`
export const profilesUpload = (uri = '') => `${endpoint.profilesUpload()}${uri}`

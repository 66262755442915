import { api } from './endpoint'

export const getSubdomain = () => {
  try {
    const hostname = window.location.hostname.split('.')
    const subdomain = hostname[0]
    return hostname.length > 2 ? subdomain : ''
  } catch (err) {
    console.error('getSubdomain error')
    return ''
  }
}
export const getTLD = () => {
  try {
    const hostname = window.location.hostname.split('.')
    return hostname[hostname.length - 1]
  } catch (err) {
    console.error('getTLD error')
    return 'com'
  }
}

const getHostname = (parts: string[]) => parts.filter(Boolean).join('.')

const getHostnameWithCluster = (cluster = '') =>
  getHostname([getSubdomain(), cluster, 'chilipiper', getTLD()])

export const fetchCluster = (
  subDomain: string,
  retry = true
): Promise<{ clusterPrefix: string }> => {
  const url = api(`/api/v1/match-cluster/${subDomain}`)
  return fetch(url)
    .then(response => response.json())
    .catch(err => {
      console.error(`error match-cluster request: ${url}`, err)
      // ONLY for IE11 windows 10 in iframe
      if (retry) {
        return fetchCluster(subDomain, false)
      }
    })
}

const redirectCanary = (hostnameWithCluster: string) => {
  if (hostnameWithCluster !== window.location.hostname) {
    const url = new URL(window.location.href)
    url.hostname = hostnameWithCluster
    window.location.replace(url.toString())
    return true
  }
}

export const maybeCanaryRedirect = (subDomain: string) => {
  const tld = getTLD()
  // PR deployments (cloud run) or localhost in production mode
  if (tld === 'app' || tld === 'localhost') {
    return Promise.resolve()
  }
  if (process.env.NODE_ENV !== 'development') {
    return fetchCluster(subDomain).then(response => {
      if (response?.clusterPrefix !== undefined) {
        redirectCanary(getHostnameWithCluster(response.clusterPrefix))
      }
    })
  }
  return Promise.resolve()
}

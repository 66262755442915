import ky from 'ky'
import { JWTResponse } from '@chilipiper/api-type-def/src/auth'
import { auth, api } from './endpoint'

export const { get, post } = ky.create({
  prefixUrl: `${auth('/api/v1')}`,
})

const {
  post: authApiPost,
  delete: authApiDelete,
  get: authApiGet,
} = ky.create({
  prefixUrl: api(''),
  credentials: 'include',
})

export const authService = {
  matchCluster(subDomain: string): Promise<string> {
    const url = `match-cluster/${subDomain}`
    return get(url)
      .json<{ clusterPrefix?: string }>()
      .then(response => {
        return response?.clusterPrefix || ''
      })
      .catch(err => {
        console.error(`error match-cluster request: ${url}`, err)
        throw err
      })
  },
  getJWTCodeAuth: (code: string) =>
    authApiPost('auth/token', {
      json: {
        code,
      },
    }).json<JWTResponse>(),
  getRefreshJWT: (refreshToken: string) =>
    authApiPost('auth/refresh/from-body', {
      json: {
        refreshToken,
      },
    }).json<JWTResponse>(),
  getRefreshJwtFromCookie: () => authApiPost('auth/refresh').json<JWTResponse>(),
  getJWTCookieAuthLegacy: () => authApiPost('auth/token-from-cookie').json<JWTResponse>(),
  revokeRefreshToken: () => authApiDelete('auth/revoke'),
  logout: () => authApiGet('logout', { redirect: 'manual' }),
  removeXSession: () => authApiGet('api/v1/internal/logout-xfire'),
}
